<template>
    <div class='fale-conosco-desk pt-5' >
        <div class="container-fluid px-0">
            <div class="row align-items-center justify-content-center pt-md-5">
                <div class="col-12 text-center stripe-mail" title="Nome comum: Cobra-cipó: Crédito: Afonso Meneses">
                    <span class="tit1">Fale</span><br />
                    <span class="tit2">Conosco</span>
                </div>
            </div>
        </div>
        <div class="container">
<!--
          <div class="row justify-content-center">
            <div class="col-12 pt-5">
              <h3>Em manutenção</h3>
              <p>Utilize o e-mail <b><a href="mailto:salve@icmbio.gov.br">salve@icmbio.gov.br</a></b> para entrar em contato conosco.</p>
            </div>
          </div>-->

            <div class="row justify-content-center">
              <div class="col-12 pt-5">
                <p>Para sugestões e comentários ou para assuntos relacionados ao processo de avaliação do risco de extinção,</p>
                <p>entre em contato conosco através do email <a href="mailto:salve@icmbio.gov.br">salve@icmbio.gov.br</a></p>
              </div>
<!--
                <div class="col-12 pt-5">
                    <p>Para sugestões e comentários ou para assuntos relacionados ao processo de avaliação do risco de extinção, preencha o formulário abaixo. </p>
                    <p>O formulário será enviado para o email <a href="mailto:salve@icmbio.gov.br">salve@icmbio.gov.br</a></p>
                </div>
                <div class="col-12 col-md-8 flex-column">
                    <mdb-input id="Nome" v-model="myName" />
                    <label for="Nome" class="label">Nome (opcional)</label>
                    <mdb-input id="Email" v-model="myEmail" />
                    <label for="Email" class="label">E-mail (opcional)</label>
                    <mdb-input id="Assunto" v-model="subject" />
                    <label for="Assunto" class="label">Assunto</label>
                    <mdb-input type="textarea" id="Mensagem" v-model="message" />
                    <label for="Mensagem" class="label">Mensagem</label>
                    <div class="d-flex buttons justify-content-between">
                        <btn @limpar="limpar" action="limpar" name="Limpar" type="stroke" />
                        <btn @enviar="enviar" action="enviar" name="Enviar" type="fill"/>
                    </div>
                </div>-->
            </div>
        </div>
    </div>
</template>

<script>
//import btn from '@/components/forms/Button.vue'
//import { mdbInput } from 'mdbvue'
import api from '@/services/api.js'

export default {
    /*components: {
        mdbInput,
        btn
    },*/
    data() {
        return {
            myName: '',
            myEmail: '',
            subject: '',
            message: '',
            msg: [],
            errors: []
        }
    },
    methods: {
        limpar(){
            this.myName = ''
            this.myEmail = ''
            this.subject = ''
            this.message = ''
        },
        enviar(){
          window.grecaptcha.ready(async () => {
            let token = await window.grecaptcha.execute(window.reCaptchaPublicKey, {action: 'search'});
            this.onRecaptchaReady(token);
          });
        },
        async onRecaptchaReady(token){
            var t = this
            this.errors = []

            if (!this.subject){
                this.errors.push('Assunto')
            }
            if (!this.message){
                this.errors.push('Menssagem')
            }
            if(this.myEmail){
                if(!this.validEmail(this.myEmail)){
                    this.errors.push('Email')
                }
            }

            if(this.errors.length > 0){
                this.notification('errors')
            } else {
                var endPoint = '/sendMail?myEmail=' + this.myEmail + '&myName=' + this.myName + '&subject=' + this.subject + '&message=' + this.message+'&reCaptchaToken='+token
                await api.get(endPoint).then(response => (
                    t.msg = response.data.msg
                ))
                this.notification('msg')
            }
        },
        notification(type) {

            if(type == "errors"){

                var typesMsg = ['Os campos Assunto e Menssagem devem ser preenchidos', 'Favor utilizar um e-mail válido']


                if(this.errors.includes('Email')){
                    this.$notification.error(typesMsg[1], { timer: 10, title: 'Atenção', showCloseIcn: true, showLeftIcn: false });
                }
                if(this.errors.includes('Email') && this.errors.length > 1){
                    this.$notification.error(typesMsg[0], { timer: 10, title: 'Formulário incompleto', showCloseIcn: true, showLeftIcn: false });
                }
                if(!this.errors.includes('Email') && this.errors.length > 0){
                    this.$notification.error(typesMsg[0], { timer: 10, title: 'Formulário incompleto', showCloseIcn: true, showLeftIcn: false });
                }

            } else {
                this.$notification.new(this.msg, { timer: 10, title: 'Concluido', showCloseIcn: true, showLeftIcn: false })

                this.limpar();
            }
        },
        validEmail(email) {
            var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        }
    }
}
</script>

<style lang="scss">
    @import '@/web/scss/form.scss';
    .notificationCenter {
        z-index: 100 !important;
        .vn-notification {
            .vn-container {
                .vn-title {
                    margin-top: 7px !important;
                    font-size: 14px !important;
                }
                .vn-message {
                    font-size: 12px !important;
                }
            }
        }
    }
    .label{
        font-weight: bold;
    }
    .tit1 {
        font-size: 2rem;
        font-weight: bold;
        line-height: 1.2;
        text-align: center;
        color: #fff;
        border-top: 3px solid #fff;
        padding-top: 1.5rem;
    }
    .tit2 {
        font-size: 2rem;
        font-weight: bold;
        line-height: 1.2;
        text-align: center;
        color: #fff;
    }
    .stripe-mail {
        background-image: url(/img/animals/cobra-full.png);
        background-size: cover;
        height: 350px;
        padding-top: 10rem;
    }
@media screen and (max-width: 600px) {
    .tit1 {
    font-size: 1.3rem;
    padding-top: .5rem;
    }
    .tit2 {
        font-size: 1.3rem;
    }
    .stripe-mail{
        height: 115px;
        padding-top: 40px;
    }
}
</style>
